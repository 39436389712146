import React from "react";
import { graphql } from "gatsby";
import Seo from "../components/shared/Seo";
import { className } from "../utils/helpers";
import * as styles from "./styles/LegalsPage.module.scss";

const LegalsPage = ({ data }) => {
	const { data: pageData } = data.allPrismicLegals.edges[0].node;

	return (
		<>
			<Seo
				title={pageData.legals_title.text}
				description={pageData.legals_description.text}
			/>

			<div {...className(styles.legalsPage, "page-content")}>
				<div dangerouslySetInnerHTML={{ __html: pageData.legal_policy_text.html }} />

				<div dangerouslySetInnerHTML={{ __html: pageData.privacy_policy_text.html }} />

				<div id="cookies" dangerouslySetInnerHTML={{ __html: pageData.cookies_policy_text.html }} />

				<div className={styles.cookieTypes}>
					{pageData.cookies_policy_types.map((cookie, i) => (
						<div className={styles.type} key={i}>
							<p>{cookie.cookie_name.text}</p>
							<div dangerouslySetInnerHTML={{ __html: cookie.cookie_data.html }} />
						</div>
					))}
				</div>
			</div>
		</>
	);
};

export const query = graphql`
	{
		allPrismicLegals {
			edges {
				node {
					data {
						legals_title {
							text
						}
						legals_description {
							text
						}
						legal_policy_text {
							html
						}
						privacy_policy_text {
							html
						}
						cookies_policy_text {
							html
						}
						cookies_policy_types {
							cookie_name {
								text
							}
							cookie_data {
								html
							}
						}
					}
				}
			}
		}
	}
`;

export default LegalsPage;
